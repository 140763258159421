import CookieConsent from 'vanilla-cookieconsent/dist/cookieconsent.umd';

CookieConsent.run({
    // root: 'body',
    // autoShow: true,
    disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,
    cookie: {
        name: 'cc_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },
    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
        consentModal: {
            layout: 'cloud',
            position: 'bottom center',
            //equalWeightButtons: true,
            //flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            //equalWeightButtons: true,
            //flipButtons: false
        }
    },
    /*
    onFirstConsent: ({ cookie }) => {
      console.log('onFirstConsent fired', cookie);
    },

    onConsent: ({ cookie }) => {
      console.log('onConsent fired!', cookie)
    },

    onChange: ({ changedCategories, changedServices }) => {
      console.log('onChange fired!', changedCategories, changedServices);
    },

    onModalReady: ({ modalName }) => {
      console.log('ready:', modalName);
    },

    onModalShow: ({ modalName }) => {
      console.log('visible:', modalName);
    },

    onModalHide: ({ modalName }) => {
      console.log('hidden:', modalName);
    },
    */
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true,  // this category cannot be disabled
            cookies: {
                name: 'app',
            },
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_pk/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: '_pk',   // string: exact cookie name
                    }
                ]
            },

            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            services: {
                matomo: {
                    label: 'Matomo',
                    onAccept: () => {
                        var _paq = window._paq = window._paq || [];
                        _paq.push(['trackPageView']);
                        _paq.push(['enableLinkTracking']);
                        (function() {
                            var u="//piwik.bithosting.eu/";
                            _paq.push(['setTrackerUrl', u+'piwik.php']);
                            _paq.push(['setSiteId', 39]);
                            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                            g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
                        })();
                    },
                    onReject: () => { },
                    cookies: [
                        {
                            name: /^(_pk)/
                        }]
                },
            }
        },
        functional: {
            services: {
                mapz_1: {
                    label: 'Mapz Location Map',
                    onAccept: () => {
                        const mapz_1_script = document.createElement('script');
                        mapz_1_script.text = "$(document).on('ready', () => { if (window.location.pathname === '/lageplan') { document.getElementsByClassName('mapz-alert')[0].style.display = 'none'; function loadMapz() {var myMapz = document.createElement('iframe');myMapz.setAttribute('src', 'https://www.mapz.com/portfolio/immomates/map/ImmoMates/embedded/bpqn0t1x/map?with_field_filters=False&lon=10.0009597327&layer=mapz_shades_of_gray&zoom=16&with_search=False&with_list=False&lat=53.5482998147&with_street_search=False');myMapz.frameBorder = '0';myMapz.style.width = '100%';myMapz.style.height = '250px';var element = document.getElementById('bpqn0t1x');element.appendChild(myMapz);}loadMapz()}});";
                        document.body.appendChild(mapz_1_script);
                    },
                    onReject: () => {},
                    cookies: [
                        {
                            name: 'cc_mapz_1',
                        }]
                },
                mapz_2: {
                    label: 'Mapz City Map',
                    onAccept: () => {
                        let mapz_2_script = document.createElement('script');
                        mapz_2_script.text = "$(document).on('ready', () => { if (window.location.pathname == '/lageplan') { document.getElementsByClassName('mapz-alert')[1].style.display = 'none'; function loadMapz1() { var myMapz1 = document.createElement('iframe');myMapz1.setAttribute('src', 'https://www.mapz.com/portfolio/immomates/map/ImmoMates/embedded/lm62ymbn/map?with_field_filters=False&lon=10.0011662628&layer=wms-hh-alkis-graublau&zoom=18&with_search=False&with_list=False&lat=53.5482679423&with_street_search=False');myMapz1.frameBorder = '0';myMapz1.style.width = '100%';myMapz1.style.height = '250px';var element1 = document.getElementById('lm62ymbn'); element1.appendChild(myMapz1);}loadMapz1()}})";
                        document.body.appendChild(mapz_2_script);
                    },
                    onReject: () => {},
                    cookies: [
                        {
                            name: 'cc_mapz_2',
                        }]
                },
                buildingloan: {
                    label: 'drklein Bauzinsrechner',
                    onAccept: () => {
                        $('.building-loan').removeClass('d-none');
                        const iframe = "<iframe class='embed-responsive' src='https://www2.finanzpartnernetz.de/go.cgi?pid=17143&wmid=3524&cpid=2&prid=51&target=bauzinsrechner_neu' style='width:100%;height:1000px;border: none;'></iframe>"
                        $('.iframe-container').append(iframe);
                    },
                    onReject: () => {},
                    cookies: [
                        {
                            name: 'cc_buildingloan',
                        }]
                },
            }
        },
        ads: {}
    },
    language: {
        default: 'de',
        autoDetect: 'document',
        translations: {
            de: {
                consentModal: {
                    title: 'Privatssphäre-Einstellungen',
                    description: 'Wir verwenden Cookies auf unserer Website und verarbeiten personenbezogene Daten, um Zugriffe auf unsere Website zu analysieren. Die Datenverarbeitung kann mit Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem du in den Privatsphäre-Einstellungen widersprechen kannst. Weitere Informationen zur Verwendung deiner Daten findest du in unserer Datenschutzerklärung.',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Weiter ohne Einwilligung',
                    showPreferencesBtn: 'Einstellungen individuell festlegen',
                    // closeIconLabel: 'Reject all and close modal',
                    footer: `
                      <a href="/impressum" target="_blank">Impressum</a>
                      <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>
                  `,
                },
                preferencesModal: {
                    title: 'Privatssphäre-Einstellungen individuell festlegen',
                    acceptAllBtn: 'Alle akzeptieren',
                    acceptNecessaryBtn: 'Weiter ohne Einwilligung',
                    savePreferencesBtn: 'Aktuelle Auswahl akzeptieren',
                    closeIconLabel: 'Pop-Up Schließen',
                    serviceCounterLabel: 'Dienst|Dienste',
                    sections: [
                        {
                            title: 'Ihre Privatsphäre-Einstellungen',
                            description: `In diesem Bereich können Sie einige Präferenzen in Bezug auf die Verarbeitung Ihrer persönlichen Daten angeben. Um Ihre Zustimmung zu den unten beschriebenen spezifischen Verarbeitungsaktivitäten zu verweigern, schalten Sie die Schalter auf "Aus" oder verwenden Sie die Schaltfläche „Alle ablehnen“ und bestätigen Sie, dass Sie Ihre Auswahl speichern möchten.`,
                        },
                        {
                            title: 'Notwendig',
                            description: 'Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich und können nicht deaktiviert werden.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: {
                                caption: 'Cookie Übersicht',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Beschreibung'
                                },
                                body: [
                                    {
                                        name: 'app',
                                        domain: location.hostname,
                                        desc: 'Der Cookie ist für die Steuerung der Software unverzichtbar. Darüber hinaus dient er der Speicherung von Affiliate-Daten und UTM-Parametern.',
                                    },
                                    {
                                        name: 'cc_cookie',
                                        domain: location.hostname,
                                        desc: 'Der Cookie von Cookieconsent sorgt dafür, dass Ihre individuellen Privatssphäre-Einstellungen auf jeder besuchten Seite automatisch wirksam sind.',
                                    },
                                ]
                            }
                        },
                        {
                            title: 'Statistik',
                            description: 'Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten werden anonymisiert und können nicht verwendet werden, um Sie zu identifizieren.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie Übersicht',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Beschreibung'
                                },
                                body: [
                                    {
                                        name: '_pk',
                                        domain: location.hostname,
                                        desc: 'Matomo (ehemals Piwik) verwendet Cookies, um Informationen darüber zu sammeln, wie Besucher unsere Website nutzen, und erstellt Berichte zur Verbesserung der Website-Performance. Die gesammelten Daten sind anonymisiert und helfen uns, das Nutzererlebnis zu optimieren.',
                                    },
                                ]
                            }
                        },
                        {
                            title: 'Funktional',
                            description: 'Diese Cookies werden benötigt, um grundlegende Zusatzfunktionen darzustellen. Dazu gehören beispielsweise Auszüge aus den Leistungen unserer Partner.',
                            linkedCategory: 'functional',
                            cookieTable: {
                                caption: 'Cookie Übersicht',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Beschreibung'
                                },
                                body: [
                                    {
                                        name: 'cc_mapz_1',
                                        domain: location.hostname,
                                        desc: 'Wir haben auf unserer Webseite Inhalte des Anbieters mapz.com eingebunden. Mapz.com ist ein Service zur Erstellung von Stadtplänen und Landkarten.',
                                    },
                                    {
                                        name: 'cc_mapz_2',
                                        domain: location.hostname,
                                        desc: 'Wir haben auf unserer Webseite Inhalte des Anbieters mapz.com eingebunden. Mapz.com ist ein Service zur Erstellung von Stadtplänen und Landkarten.',
                                    },
                                    {
                                        name: 'cc_buildingloan',
                                        domain: location.hostname,
                                        desc: 'Wir haben auf unserer Webseite einen Bauzinsrechner von drklein.de eingebunden. Dieser Bauzinsrechner erlaubt es Ihnen, die Bauzinsen, Monatsrate und Gesamtkosten Ihrer Finanzierung zu berechnen.',
                                    },
                                ]
                            }
                        },
                        /*{
                          title: 'Targeting and Advertising',
                          description: 'These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                          linkedCategory: 'ads',
                        },
                        {
                          title: 'More information',
                          description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
                        }*/
                    ]
                }
            },
            en: {
                consentModal: {
                    title: 'Privacy Settings',
                    description: 'We use cookies on our website and process personal data to analyze access to our website. Data processing can be carried out with consent or based on legitimate interests, which you can object to in the privacy settings. For more information on how your data is used, please refer to our privacy policy.',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    showPreferencesBtn: 'Manage Individual preferences',
                    // closeIconLabel: 'Reject all and close modal',
                    footer: `
                      <a href="/en/imprint" target="_blank">Imprint</a>
                      <a href="/en/privacy-policy" target="_blank">Privacy Policy</a>
                  `,
                },
                preferencesModal: {
                    title: 'Manage privacy settings',
                    acceptAllBtn: 'Accept all',
                    acceptNecessaryBtn: 'Reject all',
                    savePreferencesBtn: 'Accept current selection',
                    closeIconLabel: 'Close modal',
                    serviceCounterLabel: 'Service|Services',
                    sections: [
                        {
                            title: 'Your Privacy Choices',
                            description: `In this section, you can specify some preferences regarding the processing of your personal data. To deny your consent to the specific processing activities described below, switch the toggles to "Off" or use the "Reject All" button and confirm that you want to save your choices.`,
                        },
                        {
                            title: 'Necessary',
                            description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: {
                                caption: 'Cookie Übersicht',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Beschreibung'
                                },
                                body: [
                                    {
                                        name: 'app',
                                        domain: location.hostname,
                                        desc: 'The cookie is essential for controlling the software. It is also used to store affiliate data and UTM parameters.',
                                    },
                                    {
                                        name: 'cc_cookie',
                                        domain: location.hostname,
                                        desc: 'The cookie from Cookieconsent ensures that your individual privacy settings are automatically effective on every page you visit.',
                                    },
                                ]
                            }
                        },
                        {
                            title: 'Performance and Analytics',
                            description: 'These cookies collect information about how you use our website. All data is anonymized and cannot be used to identify you.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: '_pk',
                                        domain: location.hostname,
                                        desc: 'Matomo (formerly Piwik) uses cookies to collect information about how visitors use our website and creates reports to improve website performance. The collected data is anonymized and helps us optimize the user experience.',
                                    },
                                ]
                            }
                        },
                        {
                            title: 'Functional',
                            description: 'These cookies are required to display basic additional functions. These include, for example, extracts from the services of our partners.',
                            linkedCategory: 'functional',
                            cookieTable: {
                                caption: 'Cookie table',
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Domain',
                                    desc: 'Description'
                                },
                                body: [
                                    {
                                        name: 'cc_mapz_1',
                                        domain: location.hostname,
                                        desc: 'We have integrated content from the provider mapz.com on our website. Mapz.com is a service for creating city maps and country maps.',
                                    },
                                    {
                                        name: 'cc_mapz_2',
                                        domain: location.hostname,
                                        desc: 'We have integrated content from the provider mapz.com on our website. Mapz.com is a service for creating city maps and country maps.',
                                    },
                                    {
                                        name: 'cc_buildingloan',
                                        domain: location.hostname,
                                        desc: 'We have integrated a mortgage calculator from drklein.de on our website. This mortgage calculator allows you to calculate the construction interest rates, monthly payments, and total costs of your financing.',
                                    },
                                ]
                            }
                        },/*
            {
              title: 'Targeting and Advertising',
              description: 'Google Analytics uses cookies to gather information about how visitors use our website and creates reports to enhance website performance. The collected data is anonymized and assists us in optimizing the user experience.',
              linkedCategory: 'ads',
            },
            {
              title: 'More information',
              description: 'For any queries in relation to my policy on cookies and your choices, please <a href="#contact-page">contact us</a>'
            }*/
                    ]
                }
            }
        }
    }
});

